.job-posting-container {
  min-height: 100vh;
  background-color: #fff;
}
.table-data-col {
  color: #071437;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.job-posting-pagination-container {
  display: flex;
  flex-direction: row;
  border: 0.7px solid #c4cada;
  margin-top: 1rem;
}
.expand-more-color {
  color: #5e5e5e;
}
.job-posting-pagination-desc-container {
  display: flex;
  align-items: center;
}
.job-posting-pagination-desc {
  font-weight: 600;
  font-size: 14px;
  margin-right: 5px;
}
.job-posting-flex {
  display: flex;
}
.job-posting-card {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.job-posting-card-title {
  color: #071437;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.job-posting-detail {
  border: 1px solid lightgray;
  padding: 5px;
  margin-bottom: 1rem;
}
.job-posting-w-10 {
  width: 10%;
}
.job-posting-w-15 {
  width: 15%;
}
.job-posting-pagination {
  margin-bottom: 0;
}
.job-posting-label {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: #071437;
}
.job-posting-desc {
  color: #252f4a;
  font-size: 1rem;
  line-height: 1.25rem;
}
.apply_job_button {
  height: 27px;
  margin-left: 1em !important;
}
.flex-center {
  display: flex;
  align-items: center;
}
