.css-1w3l1ns {
  background-color: #ffd700;
  border-radius: 50px;
  color: #000;
  padding: 6px;
}

.block-btn1 {
  width: 60px !important;
  margin-top: 10px;
}

.table-header-badge {
  top: 0px;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
}

.icons {
  margin: 4px;
  border-radius: 50px;
}

tr:nth-child(even) {
  background: #f7f7f79e;
}

.badge-btn {
  background-color: rgb(51 51 51 / 11%);
  border: 1px solid #cfcfcf;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  margin: 3px;
  padding: 3px 11px;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.round {
  border-radius: 3px;
  background: #e9e9e9;
  font-weight: 500;
}

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-lg,
.navbar > .container-md,
.navbar > .container-sm,
.navbar > .container-xl,
.navbar > .container-xxl {
  max-width: 100%;
  justify-content: flex-start !important;
}

.badge-table-container {
  min-width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}

.badge-table-container .badge-table {
  table-layout: auto;
}

.nav-btn {
  width: none !important;
  margin: 8px;
}

.active-btn {
  padding: 5px 5px;
  background-color: #0b5ed7;
  color: #fff;
  border: 1px solid #0b5ed7;
}

.badge-nav-btn {
  padding: 5px 5px;
  background-color: #1d3b6d;
  border: 1px solid #1d3b6d;
}

.badge-nav-btn:hover {
  padding: 5px 5px;
  background-color: #cfcfcf;
  color: #000;
  border: 1px solid #cfcfcf;
}

.chat {
  display: flex;
  flex-direction: row;
  margin: 5px;
  padding: 5px;
}

.chat-msg {
  background: #9cd6ff;
  border-radius: 30px;
  padding: 5px;
  margin: 5px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.sent-by {
  font-size: 14px;
  margin: 5px;
  float: left;
  justify-content: left;
  display: flex;
  color: #545454;
  font-style: italic;
}

.main-chat {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.main-chat:nth-child(even) {
  justify-content: flex-end;
  align-items: flex-end;
}

.datetime {
  font-size: 12px;
  margin: 5px;
  float: left;
  justify-content: center;
  display: flex;
  color: #545454;
}

.badgebody > tr > td:first-child {
  width: 3%;
}

.badgebody > tr > td:last-child {
  text-align: left;
  white-space: break-spaces;
}

.mybadgesBody > tr > td:nth-child(4) {
  text-align: left;
  white-space: break-spaces;
}

.mybadgesBody > tr > td:nth-child(5) {
  text-wrap: balance;
  text-align: left;
  white-space: break-spaces;
}

.mybadgesBody > tr > td:nth-child(1) {
  width: 2%;
}

.mybadgesBody > tr > td:nth-child(6) {
  text-wrap: balance;
  text-align: left;
  white-space: break-spaces;
}

.pendingbadgesBody > tr > td:nth-child(5) {
  text-align: left;
  white-space: break-spaces;
}

.pendingbadgesBody > tr > td:nth-child(7) {
  text-align: left;
  white-space: break-spaces;
}

.pendingbadgesBody > tr > td:nth-child(6) {
  text-wrap: balance;
}

.pendingbadgesBody > tr > td:nth-child(1) {
  width: 2%;
}

.badgeHead {
  display: flex;
  justify-content: center;
  color: #1d3b6d;
  font-size: 1.7em;
  font-weight: 800;
  margin: 10px;
  font-family: system-ui;
}

.muiHead {
  font-size: 11px !important;
  line-height: 0px !important;
  letter-spacing: 0 !important;
  padding: 11px !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.muiTdata {
  font-weight: 500 !important;
  font-size: 17px !important;
  border-bottom: none !important;
  letter-spacing: 0 !important;
  padding: 2px !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.gold {
  color: #ffd700 !important;
}

.silver {
  color: #c0c0c0 !important;
}

.bronze {
  color: #cd7f32 !important;
}

.summary {
  width: 100% !important;
}

.badge-map {
  height: inherit;
  width: 40% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.worldmap__figure-container {
  margin: 0;
}

.css-rorn0c-MuiTableContainer-root {
  width: 60% !important;
}

.leftable {
  border: 1px solid #ddd;
  float: left;
  margin: 0px 15px 10px 15px;
  padding: 20px;
  width: 95% !important;
}

.muiTdata {
  text-align: center;
  justify-content: center;
}

.secondtable {
  border: 1px solid #ddd;
  /* float: left; */
  margin: 0px 15px 0px 15px;
  /* padding: 3px; */
  /* padding: 20px; */
  width: 95% !important;
}

/* .thirdtable {
  width: 30% !important;
  float: left;
  padding: 20px;
  border: 1px solid rgb(221, 221, 221);
  margin-left: 20px;
} */

.count-main {
  display: flex;
  justify-content: center;
}

.inner-count {
  align-items: flex-end;
  display: flex;
  font-size: 15px;
}

.Mui-expanded .MuiAccordionSummary-gutters {
  min-height: 0px !important;
  height: 28px !important;
}

.textModal {
  color: #000000;
  font-size: 17px;
  font-family: sans-serif;
  margin: 1px;
  display: flex;
  align-items: center;
  text-align: left;
}

.media-icons a {
  text-align: center;
  line-height: 33px;
  height: 35px;
  margin: 0 4px;
  font-size: 14px;
  color: #fff;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.containerBadge .button {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px;
}

.button label {
  height: 15px;
  width: 15px;
  border-radius: 20px;
  background: #fff;
  margin: 0 4px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.button label.active {
  width: 35px;
}

.font-text {
  font-size: 13px;
  padding: 2px;
}

.react-quill-badge > .ql-container > .ql-editor {
  height: 47px;
  margin: 10px;
  width: 250px;
}

.react-quill-badge > .ql-container.ql-snow {
  border-radius: 7px;
}

.pod-name {
  text-decoration: underline;
  color: #1d3b6d;
  cursor: pointer;
}

.pod-details-inner {
  display: flex;
}

.pod-details-inner p {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.pod-details-inner p span {
  margin: 2px;
  width: fit-content;
}

.badge-sticky-footer {
  position: sticky;
  bottom: 0;
  background: white;
  z-index: 10;
  /* padding: 2px 4px; */
  border-top: 1px solid #e0e0e0;
  box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.25);
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.badge-sticky-footer .page-control {
  border: none;
}
