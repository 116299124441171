.main-desc {
  display: flex;
  width: 100%;
}

.info-collab {
  display: flex;
  align-items: center;
  font-size: small;
}
.main {
  display: flex;
  width: 100%;
  padding: 30px;
  flex-direction: column;
  overflow: auto;
  border-left: 1px solid #ddd;
}
.info-collab > p {
  color: rgba(0, 0, 0, 0.4);
  margin: 0 10px;
}

.info-collab > p > span {
  color: rgba(0, 0, 0, 0.8);
  margin: 0 5px;
}

.arrow {
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.all-options > .MuiSvgIcon-root {
  color: rgba(0, 0, 0, 0.25);
  font-size: large;
  margin: 5px 0;
}

/* .ql-syntax {
  display: flex;
  width: 90%;
  overflow: hidden;
} */
pre {
  display: flex;
  width: 90px;
  flex-wrap: wrap;
}
.post-ans-btn {
  padding: 10px;
  background-color: #0095ff;
  color: #fff;
  border: 2px solid #007cd446;
  outline: none;
  border-radius: 3px;
  cursor: pointer;
}
.comment-btn {
  padding: 6px;
  background-color: #0095ff;
  color: #fff;
  border: 2px solid #007cd446;
  outline: none;
  border-radius: 3px;
  cursor: pointer;
}
.arrow-col {
  font-size: 2rem;
  color: rgb(29 59 109);
  cursor: pointer;
}

.question-answer-view pre.ql-syntax {
  background-color: #e7e7e761;
  color: #1c1c1c;
  overflow: overlay;
  width: 50%;
  padding: 8px;
  font-size: 13px;
  border-radius: 8px;
}
.go-back-question {
  background-color: #00000099;
  color: #fff;
  border-color: #00000099;
  line-height: 1;
}
.question-answer-view {
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px;
}
.question-answer-view > a {
  color: #0151f0d8;
  font-size: 1.1rem;
  margin-bottom: 10px;
}
.question-answer-view > p > a {
  color: #0151f0d8;
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.question-answer-view > p > a:hover {
  color: #1653ccd8;
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.question-answer-view > a:hover {
  color: #1649b1d8;
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.question-answer-view > p {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.7);
  /* margin-bottom: 10px; */
}
.all-questions-post {
  display: flex;
  width: 100%;
  padding: 9px;
  width: 100%;
}

.subscribe-btn {
  width: 100%;
  padding: 3px;
  background-color: #0095ff;
  color: #fff;
  border: 2px solid #007cd446;
  outline: none;
  border-radius: 3px;
  cursor: pointer;
}

.unsubscribe-btn {
  width: 100%;
  padding: 3px;
  background-color: #dc3545;
  color: #fff;
  border: 2px solid #dc3545;
  outline: none;
  border-radius: 3px;
  cursor: pointer;
}
