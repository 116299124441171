.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a:link {
  text-decoration: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* SCROLL Bar */
body::-webkit-scrollbar,
.table-container::-webkit-scrollbar {
  width: 0.7em;
}

body::-webkit-scrollbar-track,
.table-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(80, 80, 80, 0.438);
  border-radius: 1px;
}

body::-webkit-scrollbar-thumb,
.table-container::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 5px;
}

.landing {
  justify-content: center;
  background-color: whitesmoke;
  box-shadow: 0px 1px 10px 4px #cbcbcb;
  border-radius: 9px;
  width: 40%;
  align-self: center;
  position: absolute;
  left: 30%;
  top: 30%;
  padding: 30px;
}

.homepage {
  justify-content: center;
  border: 2px solid black;
  background-color: whitesmoke;
  border-radius: 9px;
  width: 70%;
  align-self: center;
  position: absolute;
  left: 15%;
  top: 30%;
  padding: 30px;
}

.heading {
  text-align: center;
  margin: 20px 20px;
  font-size: 35px;
  font-family: sans-serif;
}

.email-btn {
  color: black;
  font-size: 1.2em;
}

.tableClone {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.container {
  justify-content: left;
  margin-left: 2rem;
}

.navbar-parent {
  /* max-height: 10vh; */
  background-color: rgb(250, 250, 250);
  box-shadow: 0px 1px 10px 4px #cbcbcb;
}

.navbar {
  font-size: 14px;
  box-shadow: 0px 2px 6px 1px #a5a5a5;
}

.body-container {
  width: 95%;
  margin: 2px auto;
}

.dark-blue {
  background-color: #0f3460;
}

.dark-blue-text {
  color: #0f3460;
}

.padding-100 {
  padding: 130px;
}

.m_r-5 {
  margin-right: 20px;
}

.table-container {
  max-height: 74vh;
  table-layout: fixed;
  border-collapse: collapse;
  overflow-y: scroll;
  font-size: 12px;
  box-shadow: 1px 1px 9px 2px #c7c7c7ed;
  padding: 2 8px;
  background-color: white;
}

.table-container td {
  padding: 4px 5px;
  overflow: hidden;
  white-space: nowrap;
  border: 2px solid #d4d4d4ce;
  vertical-align: middle;
}

.table-container th {
  border: 2px solid #d4d4d4ce;
}

.table-container tr {
  transition: all 0.05s linear;
  max-height: 85vh;
}

.table-container tr:hover {
  background-color: #d4d4d4ce;
}

.table-container tr .form-control {
  font-size: 0.8rem;
  padding: 0.2rem 0.2rem;
}

.t-container {
  width: 95%;
  margin: 10px auto;
}

.thead {
  background-color: #1b3c6d;
  color: white;
  padding: 1rem;
  text-transform: capitalize;
  font-size: 0.8rem;
}

.disable-input {
  pointer-events: none;
  color: lightcoral;
}

.highlight {
  background-color: rgb(255, 230, 89);
}

.modal-body {
  max-height: 70vh;
  overflow-y: scroll;
}
.modalBody {
  height: auto !important;
  max-height: none !important;
  overflow-y: visible;
}
.insert_row_model {
  width: 100%;
}

.home_grid_container {
  padding: 2rem;
}

.flex-container {
  display: flex;
}

.left-component {
  width: 40%;
  height: 88vh;
  padding-top: 20px;
  font-size: 14px;
}

.left-component .row {
  padding-right: 0;
}

.separator {
  position: relative;
  order: 2;
  width: 2%;
  height: auto;
  border-right: 1px solid rgb(206, 206, 206);
}

.right-component {
  order: 3;
  width: 100%;
}

.placholder-img {
  width: 100%;
  height: auto;
}

.filterlist_row {
  margin-bottom: 0.3rem;
}

.menu_img {
  height: 50px;
  width: 70%;
  object-fit: contain;
}

.menu_text {
  text-align: center;
}

.menu_link {
  display: flex;
  justify-content: space-around;
}

.component-collapse {
  position: relative;
  animation: slide-left 0.3s linear forwards;
}

@keyframes slide-left {
  0% {
    visibility: hidden;
  }

  100% {
    visibility: hidden;
    width: 0;
  }
}

.toggle-btn {
  position: absolute;
  display: inline-block;
  width: 25px;
  height: 25px;
  font-size: 25px;
  cursor: pointer;
  right: 0;
}

.home-card {
  box-shadow: 1px 1px 9px 2px #c7c7c7ed;
  border: 1px solid rgb(0 0 0 / 27%);
}

.home-card:hover {
  box-shadow: 1px 1px 2px 1px #c7c7c7;
}

.table-taskbar {
  width: 100%;
  height: 40px;
  background: rgba(238, 238, 238, 0.808);
}

.action-items {
  display: inline-block;
  width: 50px;
  height: 100%;
  background: rgba(238, 238, 238, 0.808);
  text-align: center;
  justify-content: center;
  font-size: 20px;
  border: 1px solid rgb(161, 161, 161);
  transition: 0.2s linear;
}

.action-items:hover {
  background: rgb(192, 192, 192);
  cursor: pointer;
}

.home-container {
  margin-top: 30px;
}

.spinner-container {
  width: 100%;
  text-align: center;
  height: 70vh;
}

.progessBar {
  background-color: rgb(68, 212, 231);
  color: white;
  padding: 1px;
  margin-top: 10px;
}

.file-upload {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1rem;
}

.upbutton {
  width: 5rem;
  padding: 0.5rem;
  background-color: #2767e9;
  color: aliceblue;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 10px;
}

.display-none {
  display: none;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.table-header {
  top: 0px;
  padding-bottom: 5px;
  display: flex;
}

.username-login {
  font-weight: 500;
  font-family: system-ui;
  color: #000;
  border-radius: 9px;
  margin: 8px;
  padding: 8px;
  border: 1px solid #bbb;
}

.username-login::after {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 5px;
  background-color: green;
  display: inline-block;
}

.block-btn {
  width: 90px;
  margin-top: 10px;
}

.right-menu-items {
  font-size: 20px;
  padding: 5px 7px 9px 7px;
}

.circle-bg {
  background: #afafaf59;
  padding: 3px 7px;
  border-radius: 50%;
  color: black;
  cursor: pointer;
  box-shadow: 1px 0px 4px 1px #a7a7a7ba;
}

.circle-bg:hover {
  box-shadow: 1px 0px 2px 1px #a7a7a7;
}

.table-row-border {
  border-bottom: 0.6px solid rgb(175 175 175);
}

.table-row-border:hover {
  background-color: #c9c9c9e3;
}

.audit-container td {
  white-space: nowrap;
  max-width: 170px;
  overflow: hidden;
  padding: 5px 8px 5px 0px;
  text-overflow: ellipsis;
}

.main-button {
  padding: 5px 5px;
  background-color: #6388c9;
  color: white;
}

.main-button-cancel {
  padding: 5px 5px;
  color: white;
}

.applyall {
  background-color: #6388c9;
  /* Green */
  color: white;
  border: 0.7px solid white;
}

.space {
  width: 4px;
  height: auto;
  display: inline-block;
}

.cloneNav {
  padding: 10px;
  margin: 20px auto;
  background-color: rgb(174, 213, 239);
}

.inlineedit {
  font-size: 10px;
  display: inline;
  white-space: nowrap;
  margin-right: 20px;
}

.applyover {
  display: inline;
  white-space: nowrap;
}

.inlineinternal {
  border: 0.2px solid white;
  font-size: 15px;
  align-items: center;
}

.inlineinternalSave {
  font-size: 15px;
  align-items: center;
}

.inlineinternalClose {
  border: 0.2px solid #c70000ed !important;
  font-size: 15px;
  align-items: center;
  background-color: #c70000 !important;
}

.main-button:hover {
  background-color: rgba(235, 235, 235, 0.829);
  color: black;
}

.page-control {
  margin-top: 10px;
  font-size: 14px;
  border: 0.7px solid rgb(43, 43, 43);
  padding: 3px;
  background-color: white;
  border-radius: 3px;
}

.page-number-input {
  width: 70px;
  border: none;
  border-bottom: 0.5px solid black;
}

.page-of {
  display: inline-block;
}

.prev-next-btn {
  display: inline-block;
  margin-left: 10px;
}

.second-control {
  display: inline-block;
  margin-left: 10px;
}

.modal-content {
  font-size: small;
}

.modal-xl {
  width: 95%;
}

/* LOAD BTN */

.load-btn {
  position: relative;
  cursor: not-allowed;
}

.load-btn::after {
  content: '';
  display: inline-block;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  animation: loading;
  background-color: rgba(151, 195, 245, 0.753);
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

@keyframes loading {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.top-toolbar button {
  margin: 5px;
  border: 1px solid rgb(94, 170, 241);
  background-color: #6388c9;
  color: white;
  border-radius: 3px;
}

.sticky-table-header {
  position: sticky;
  top: 0px;
  background-color: white;
}

.key-icon {
  margin-left: 20px;
  font-size: 19px;
}

.column-select-container {
  padding: 3px 10px;
  height: 40vh;
  overflow-y: auto;
}

.dropdown button {
  font-size: small;
  margin-top: 5px;
  margin-bottom: 5px;
}

.top-toolbar {
  display: flex;
  position: sticky;
  left: 0px;
  z-index: 1;
}

.ocr-toolbar {
  display: flex;
  align-items: center;
  position: sticky;
  left: 0px;
  z-index: 1;
  padding: 4px 0;
  margin-right: 10px;
}

.top-toolbar .dropdown {
  width: auto;
  margin-right: 20px;
}

.top-toolbar p {
  width: auto;
  margin: 0;
  padding-top: 10px;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: ' Import';
  display: inline-block;
  border-radius: 3px;
  padding: 0 0 0 5px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
}

.custom-seed-file-input::before {
  content: ' Seed';
  display: inline-block;
  border-radius: 3px;
  padding: 0 0 0 5px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
}

.custom-seed-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input:hover::before {
  border-color: black;
}

#file-input {
  padding-top: 0;
  width: 50px;
  cursor: pointer;
}

.right-toolbar {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.y-scroll {
  overflow-y: auto;
}

.toolbar-icon {
  font-size: large;
}

.error-log-btn {
  font-size: 1.1em;
  padding: 4px;
}

.sign-in-msg {
  font-size: 1.5rem;
  font-weight: bold;
}

.login-button-container {
  height: 80vh !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.add-ons {
  font-size: 1.2rem;
  background-color: #0d6efd;
  color: white;
}

.hidden-col {
  position: absolute;
  right: 0;
  width: 200px;
}

.header-snowflake-clone {
  color: white;
}

#tableauViz {
  display: flex;
  align-items: center;
  justify-content: center;
}

.worldmap__figure-container {
  background: none !important;
}

.center-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.center-image img {
  max-width: 75%;
  height: 75%;
}

.card-container {
  position: relative;
  padding: 20px;
}

.ribbon {
  position: absolute;
  top: 20px;
  left: 21px;
  background: #239faf;
  color: white;
  padding: 3px 8px;
  z-index: 1;
  font-weight: bold;
  display: flex;
}

.ref-data-table-select .dropdown-toggle {
  background: white;
  color: black;
  width: 30rem;
  text-align: left;
}

@media (max-width: 991px) {
  .ref-data-table-select .dropdown-toggle {
    width: 90vw;
  }

  .table-select-dropdown-menu {
    max-height: 20vw;
  }
}

.ref-data-table-select .dropdown-toggle::after {
  display: none !important;
  /* replace with custom icon */
}

/* Fixed Height Drop down menu*/
.table-select-dropdown-menu {
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Compact Menu Items */
.table-select-dropdown-menu .dropdown-item {
  font-size: 12px !important;
  padding: 0 1rem;
}

/* Slim divider */
.table-select-dropdown-menu .dropdown-divider {
  margin: 0.2em 0;
}

/* SCROLL Bar */
body::-webkit-scrollbar,
.table-select-dropdown-menu::-webkit-scrollbar {
  width: 0.7em;
}

body::-webkit-scrollbar-track,
.table-select-dropdown-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(80, 80, 80, 0.438);
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb,
.table-select-dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 5px;
}

.menu_img_nps {
  width: 60%;
  object-fit: contain;
  height: 50px;
}

li.ql-mention-list-item {
  font-size: small;
  display: flex;
  height: 51px;
  line-height: 19px;
  align-items: center;
}

.dropdown-profile .dropdown-menu[data-bs-popper] {
  top: 100%;
  right: 0;
  left: auto;
}

.dropdown-profile .dropdown-toggle::after {
  content: none;
}

.profile-small img {
  max-width: 3rem;
  border-radius: 50%;
  padding: 2px;
  background: #7d85eb;
}

.profile_details #name {
  font-size: 18px;
  font-weight: 600;
}

@media screen and (max-width: 600px) {
  .dropdown-profile .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    right: 0;
  }
}

img {
  max-width: 100%;
}

.quill-image {
  max-width: 600px;
  max-height: 400px;
}

.app-notication-container {
  margin-bottom: 2px;
  margin-bottom: 2px;
}

.app-notification-icon-button {
  position: relative;
}

.app-notification-icon-button:hover {
  background-color: rgba(25, 118, 210, 0.04);
}

.app-notification-badge .MuiBadge-badge {
  background-color: #dc004e;
  color: white;
  font-weight: bold;
  font-size: 0.75rem;
  height: 18px;
  min-width: 18px;
}

.app-notification-badge .MuiBadge-badge:empty {
  background-color: transparent;
}

.app-notification-icon {
  font-size: 26px;
  color: #757575;
  stroke-width: 0.2;
}

.app-notification-icon:hover {
  color: #1565c0;
}

.app-notification-icon-unread {
  color: #1976d2;
}

.app-notification-popover .MuiPopover-paper {
  max-width: 500px;
  max-height: 500px;
}

.app-notification-filters {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 2px;
}

.app-notification-item {
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: background-color 0.2s ease;
  cursor: pointer;
}

.app-notification-item:hover {
  background-color: #eeeeee;
}

.app-notification-item-unread {
  border-left: 4px solid;
}

.app-notification-content {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
}

.app-notification-icon-wrapper {
  margin-right: 8px;
  margin-top: 2px;
}

.app-notification-text {
  flex-grow: 1;
  padding-right: 32px;
}

.app-notification-title {
  font-weight: 600;
  word-break: break-word;
}

.app-notification-message {
  color: #666;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* -webkit-line-clamp: 2; */
  -webkit-box-orient: vertical;
}

.app-notification-footer {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 2px;
  padding-top: 8px;
  margin-top: 8px;
  border-top: 1px solid;
}

.app-notification-timestamp {
  color: #999;
}

.app-notification-close-button {
  color: #999;
}

.app-notification-close-button:hover {
  color: #666;
  background-color: rgba(0, 0, 0, 0.04);
}

.app-notifications-unavailable {
  text-align: center;
  align-content: center;
  display: 'flex';
  width: 485px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  background-color: #fff;
  border-radius: 6px;
  padding: 16px;
}

.app-right-menu-items-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-brand {
  padding: 0;
  margin-right: 20px;
  padding: 0;
  margin-right: 20px;
}
.idea_category_badge,
.idea_complexity_badge {
  color: #4b5675;
  background-color: #f9f9f9;
  justify-content: center;
  line-height: 1;
  border-radius: 0.25rem;
  padding: 0.5rem;
  border: 1px solid #dbdfe9;
  font-weight: 600;
  font-size: 0.6875rem;
  display: inline-flex;
  align-items: center;
  margin-right: 5px;
}

.badge-deprecate {
  color: #fff;
  background-color: #808290;
  border-color: #808290;
}
.badge-danger {
  color: #f8285a;
  background-color: #ffeef3;
  border-color: #f8285a33;
}
.badge-info {
  color: #7239ea;
  background-color: #f8f5ff;
  border-color: #7239ea33;
}
.badge-warning {
  color: #f6b100;
  background-color: #fff8dd;
  border-color: #f6b10033;
}
.badge-primary {
  color: #1b84ff;
  background-color: #eff6ff;
  border-color: #1b84ff33;
}
.badge-success {
  color: #17c653;
  background-color: #eafff1;
  border-color: #17c65333;
}
/* Badges - Start */
.badgeInner-gold {
  display: flex;
  margin: 10px;
  padding: 8px;
  width: 95%;
  background: #f9d5174d;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.4s ease;
  align-items: center;
}
.badgeInner-silver {
  display: flex;
  margin: 10px;
  padding: 8px;
  width: 95%;
  background: #cdcdcd4d;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.4s ease;
  align-items: center;
}
.badgeInner-bronze {
  display: flex;
  margin: 10px;
  padding: 8px;
  width: 95%;
  background: #fb902638;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.4s ease;
  align-items: center;
}
.badgeInner-bronze:hover {
  transform: translateY(-10px);
}
.badgeInner-gold:hover {
  transform: translateY(-10px);
}
.badgeInner-silver:hover {
  transform: translateY(-10px);
}
/* Badges - End */
/* Ideas - Start */
.idea_title {
  display: block;
  font-weight: 600;
  color: #071437;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1px;
  max-width: 278px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* Ideas - End */
html {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #fff;
}
body {
  margin: 0;
  padding: 0;
  background-color: #fff;
  background-size: cover;
  height: 100%;
}
@media (min-width: 768px) {
  body {
    font-size: 16px;
  }
}
ul {
  list-style-position: inside;
  padding: 0;
}
p {
  margin: 0;
}
a {
  color: rgba(0, 0, 0, 0.533);
}
.Mui-expanded .MuiAccordionSummary-gutters {
  min-height: 0px !important;
  height: 28px !important;
}
.idea-button {
  border: 1px dashed #1b84ff !important;
  color: #1b84ff !important;
  line-height: 1 !important;
  font-weight: 600 !important;
  min-width: initial !important;
  font-size: 0.8125rem !important;
  padding-bottom: 0.25em !important;
}
.user-profile-heading {
  color: #071437;
  text-align: start;
  margin-top: 15px;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.8125rem;
}
.app-notification-box-continer {
  background-color: #fff;
  border-radius: 6px;
  padding: 8px;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.app-notification-list {
  scrollbar-width: thin;
  scrollbar-color: #bdbdbd #f5f5f5;
  scroll-behavior: smooth;
}
.dashboard-transform-capitalize {
  text-transform: capitalize;
}
.badge_container_desc {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}
/* accordion - start */
.job-posting-accordion {
  background-color: #fff;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  border-color: #f1f1f4;
  border-radius: 0.75rem;
}
.job-posting-card-detail {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.job-summary-section {
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  border-radius: 0.375rem;
  gap: 0.375rem;
}
.job-summary-text {
  font-size: 0.875rem;
  line-height: 1;
  text-transform: capitalize;
  color: #071437;
  font-weight: 500;
}
.job-summary-label {
  color: #4b5675;
  font-size: 0.75rem;
  line-height: 1rem;
}
/* accordion - end */
/* Nav Menu - start */
.nav-menu {
  background-color: #fff;
}
.collab-search-container {
  display: flex;
  width: 90%;
  font-size: 0.9em;
  font-weight: 400;
  border: 2px solid #b6c4b6;
  border-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  transition: 0.3s box-shadow;
}

.collab-search-container:focus-within {
  border-color: #007cd4;
  border-right: none;
  box-shadow: -8px 2px 24px -11px rgba(0, 0, 0, 0.39);
  -webkit-box-shadow: -8px 2px 24px -11px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: -8px 2px 24px -11px rgba(0, 0, 0, 0.39);
}
.search-button {
  background: green;
  padding: 7px;
  font-size: 15px;
  border: 1px solid green;
  border-radius: 2px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  color: white;
  opacity: 0.8;
  transition: 0.3s opacity;
}
.search-button:hover {
  opacity: 1;
}
.search-bar-collab {
  width: 57%;
  display: flex;
}
.clearbtn {
  display: inline-block;
  border: none;
  background: transparent;
  padding: 0;
}
.collab-search {
  width: 100%;
  padding: 7px;
  font-size: 1.1em;
  font-weight: 400;
  outline: none;
  border: none;
}

.collab-search::placeholder {
  color: black;
  opacity: 0.4;
}
/* Nav Menu - end */
/* Pagination - start */
.pagination {
  display: flex;
  letter-spacing: 1px;
  font-family: monospace;
}
.page-item {
  margin: 5px;
}
ul.pagination li.active {
  background-color: #1d3b6d;
}
/* Pagination - End */
.ql-editor {
  height: 200px;
}
/* Summary Tile - Start */
.summary-tile-div {
  display: flex;
  justify-content: space-around;
  margin-top: 0.75em;
}
.summary-tile-container {
  background-color: #fff;
  border: 1px solid #dbdfe9;
  width: 22%;
  cursor: pointer;
}
.summary-tile {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: center;
  padding: 1.5rem 3rem;
  background-color: #fff;
  min-height: 5em;
  border-radius: 5px;
}
.summary-tile-count {
  color: #071437;
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.summary-tile-link {
  border-bottom: 1px dashed #1b84ff;
}
.summary-tile-desc {
  display: flex;
  color: #1b84ff;
  align-items: center;
}
/* Summary Tile - End */
/* user profile - start */
.containerBadge {
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}
.main-card {
  max-width: 40%;
  transition: 1s;
  margin-right: 30px;
}
.tabs-view {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.details {
  display: flex;
  margin: 5px;
  flex-direction: column;
  text-align: left;
  font-size: 15px;
}
.details-request {
  display: flex;
  flex-direction: column;
  padding: 15px;
  font-size: 15px;
  /* font-weight: 500; */
  background: #dde7f9cc;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.4s ease;
  margin-bottom: 13px;
}
.details-request:hover {
  transform: translateY(-10px);
}
.details-inner {
  display: flex;
}
.details-text {
  margin-left: 5px;
  max-width: 100%;
  white-space: break-spaces;
  overflow-wrap: normal;
  display: flex;
}
.cards {
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
}
.cards .card {
  width: 100%;
  background: #fff;
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.4s ease;
}
.main-card .cards .card:hover {
  transform: translateX(10px);
}
.cards .card .content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.cards .card .content .img {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  padding: 3px;
  background: #7d85eb;
  margin-bottom: 14px;
}
.edit-icon {
  background: none;
  border: none;
  border-radius: 5px;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.edit-icon:hover {
  background: #f5f5f5;
  cursor: pointer;
}
.card .content .img img {
  height: 100%;
  width: 100%;
  border: 3px solid #ffff;
  border-radius: 50%;
  background: #d6d9ff;
  object-fit: cover;
}
.card .content .name {
  font-size: 15px;
  font-weight: 500;
}
.card .content .job {
  font-size: 16px;
  color: #000;
}
.card .content .media-icons {
  margin-top: 10px;
  display: flex;
}
.user-profile-tabs .nav-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  background-color: #007bff;
}

.user-profile-tabs .nav-item {
  margin-bottom: -1px;
}

.user-profile-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #555758;
  padding: 10px 45px;
  margin-right: 10px;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  background-color: #cbd0d4;
  flex: 1;
  text-align: center;
}

.user-profile-tabs .nav-link:hover {
  background-color: #e9ecef;
  color: #007bff;
  border-color: #dee2e6;
}

.user-profile-tabs .nav-link.active {
  color: #fff;
  background-color: #007bff;
  border-color: #dee2e6 #dee2e6 #fff;
}
/* user profile - end */
