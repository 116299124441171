.badge-circle {
  border-radius: 50%;
  padding: 0;
  min-width: unset;
  width: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  min-width: 1.5rem;
  line-height: 0;
  color: #ffffff;
  font-size: 0.85rem;
  font-weight: 600;
}
.badge-circle-info {
  background-color: #7239ea;
}
.badge-circle-primary {
  background-color: #1b84ff;
}
.accordion-wrapper {
  position: relative;
  overflow: hidden;
}
.accordion-summary-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.accordion-table-content {
  table-layout: fixed;
  width: 100%;
}
.accordion-table-label {
  width: 20%;
  font-weight: bold;
  border: 1px solid #ddd;
  padding: 8px;
  word-wrap: break-word;
}
.accordion-table-detail {
  width: 80%;
  border: 1px solid #ddd;
  padding: 8px;
}
.Mui-expanded > .accordion-summary-content > .accordion-status-bar {
  border-bottom-right-radius: initial;
  position: absolute;
  width: 100%;
  bottom: 0;
}
