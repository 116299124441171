.ops_card {
  transition: box-shadow 0.3s, transform 0.3s;
  position: relative;
  background-color: #d9dcdf !important;
  padding: 10px;
  margin-bottom: 600px;
  border-radius: 25px;
}

.ops_card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.kudos_card {
  transition: box-shadow 0.3s, transform 0.3s;
  padding: 10px;
  margin-bottom: 600px;
  display: grid;
  grid-template-columns: 10% 90%;
  width: 100%;
  border-left: 5px solid #52489c;
  transition: all 1s ease;
}
.submitter-form .react-quill-badge > .ql-container > .ql-editor {
  margin: 0;
  height: 43px;
}
.kudos_card > #kudo_icon {
  grid-column: 1;
  grid-row: 1 / span 3;
  display: flex;
  align-items: center;
  margin: 5px;
  color: #52489c;
  font-size: 28px;
}

.kudos_card > #kudo_message {
  grid-column: 2;
  display: grid;
  margin: 10px 0 10px 0;
  font-style: italic;
  font-size: 14px;
  color: #52489c;
}

.kudos_card > #kudo_sender {
  text-align: right;
  font-size: 12px;
  color: gray;
  padding: 2px;
}

.kudos_card > #kudo_date {
  text-align: right;
  font-size: 10px;
  color: gray;
}

.kudos_card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-3px);

  #kudo_icon {
    font-size: 30px;
  }
}

.kudos-jumbotron {
  border-radius: 6px;
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
  padding: 1em;
}
.kudos-jumbotron-heading {
  font-weight: 600;
}
.kudos-jumbotron-list {
  margin-bottom: 0;
}
.kudos-submit-button {
  width: fit-content !important;
}
.send-kudos-header {
  background-color: #1d3b6d;
  color: #fff;
}
.send-kudos-header .btn-close {
  background-color: #fff;
}
/* Quillmention - start */
.ql-mention-list-container {
  width: 270px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
  z-index: 9001;
}

.ql-mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.ql-mention-list-item {
  cursor: pointer;
  height: 44px;
  line-height: 44px;
  font-size: 16px;
  padding: 0 20px;
  vertical-align: middle;
}

.ql-mention-list-item.selected {
  background-color: #d3e1eb;
  text-decoration: none;
}

.mention {
  height: 24px;
  width: 65px;
  border-radius: 6px;
  background-color: #d3e1eb;
  padding: 3px 0;
}

.mention > span {
  margin: 0 3px;
}
/* Quillmention - end */
