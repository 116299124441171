#video {
  position: fixed;
  z-index: -1;
  top: 0;
}
@media (min-aspect-ratio: 16/9) {
  #video {
    width: 100vw;
    height: auto;
  }
}
@media (max-aspect-ratio: 16/9) {
  #video {
    width: auto;
    height: 100vh;
  }
}

h1 {
  font-size: 2rem;
  line-height: 3rem;
  margin: 0.5rem 0;
}
a {
  color: #fff;
}

.app > * {
  margin: 1rem;
}
.app > * + * {
  margin-top: 0;
}
.info,
.graph {
  background-color: rgb(239 239 239);
  border-radius: 4px;
  color: #000;
  font-weight: 500;
}
.graph {
  /* VisJS does not handle resizing well, try to keep some space for the info box */
  height: calc(100vh - 100px);
}

.info {
  padding: 1rem;
}
.graph div.vis-tooltip {
  width: auto;
  white-space: normal;
  background-color: #3e93e2c4;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.2rem 1rem;
  text-align: center;
  font-weight: 300;
}
.tooltip-title {
  border-bottom: 1px solid #fff;
  padding: 0.2em 0.8em;
  font-weight: 500;
}
.vis-tooltip {
  overflow-wrap: anywhere;
  transform: scale3d(1, 1, 1);
  transition: 0.2s ease;
}
.controls {
  display: flex;
}
.controls-select {
  flex: 1;
}

.tabs {
  display: flex;
  padding: 8px;
  margin: 8px;
}

.tab1 {
  /* background: #297895; */
  color: rgb(255, 255, 255);
  margin: 5px;
  padding: 8px;
  border: 1px solid #fff;
  border-radius: 54px;
}
.tab1:hover {
  border: 1px solid green;
}

/* .tab2 {
  background: #297895;
  color: rgb(255, 255, 255);
  border-radius: 50px;
  padding: 8px;
  margin-left: 20px;  
} */

.column {
  padding: 16px;
  margin: 6px;
  letter-spacing: 1px;
}

.tableData {
  transition: '0.2s ease';
  transform: scale3d(1, 1, 1);
}
.nonodes {
  display: flex;
  font-size: 17px;
  margin-left: 30px;
  font-weight: 500;
  justify-content: center;
}
