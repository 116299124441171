.okr-container {
  font-family: Arial, sans-serif;
  max-width: 900px;
  margin: auto;
  padding: 20px;
  background: #f4f4f4;
  border-radius: 8px;
}

/* h1 {
  text-align: center;
  color: #333;
}

h2 {
  text-align: center;
  color: #555;
} */

.okr-section {
  background: white;
  padding: 15px;
  margin: 10px 0;
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

/* h3 {
  color: #222;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
} */

.okr-ul {
  list-style-type: square;
  padding: 0;
}

.okr-ul-li {
  padding: 5px 0;
}

/* b {
  color: #007bff;
} */
.okr-table-cell {
  color: white !important;
  font-weight: bold !important;
}
.table-cell-okr-main {
  border-right: 1px solid #ebebeb;
}
