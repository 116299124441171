.card-section-nps {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-content: center;
}

.logo-nps {
  margin-left: auto;
  width: 20%;
  height: 20%;
}

.card_survey_title {
  display: block;
  font-weight: 600;
  color: #071437;
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-bottom: 1px;
  max-width: 278px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.survey_status {
  color: #4b5675;
  background-color: #f9f9f9;
  justify-content: center;
  line-height: 1;
  border-radius: .25rem;
  padding: .5rem;
  border: 1px solid #dbdfe9;
  font-weight: 600;
  font-size: .6875rem;
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
}
.survey_title {
  font-weight: 500;
  color: #071437;
  font-size: 1.3rem;
  line-height: 2.5rem;
  margin-bottom: 8px;
  max-width: 278px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}
.survey_description{
  font-size: 0.8em;
  line-height: 1.2rem;
  color: #4b5675;
  margin-bottom: 10px;
  max-width: 278px;
}
