.okr-btn {
  margin-top: 10px;
}

.okrHeader {
  padding: 0px;
  margin: 0px;
  justify-content: space-between;
  display: flex;
}
.okrMainDetail {
  padding: 10px;
  display: flex;
  font-size: 10px;
}
.mainDetailText {
  padding: 3px;
  border: 1px solid #bbbbbb;
  margin: 9px !important;
  border-radius: 6px;
}

.rich-text-editor-okr {
  height: auto !important;
}

.statusDiv {
  width: 22%;
  background-color: #dbdbdb7a;
}
.mainDetailTextStatus {
  padding: 2px;
}
.card-text-okr {
  display: flex;
  justify-content: space-between;
  font-size: 20px !important;
  font-weight: 100 !important;
  font-family: sans-serif !important;
}

.color-select {
  padding: 2px;
  border-bottom: 1px solid #1976d2 !important;
}
.okr-modal > .modal-dialog {
  width: 70% !important;
  max-width: 100% !important;
}
.addKeyResult {
  margin-left: auto !important;
  margin-bottom: '15px';
  width: 30%;
}
