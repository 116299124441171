.status-category {
  display: inline-flex;
  align-items: center;
  text-transform: capitalize;
  font-size: 0.65rem;
  font-weight: 600;
}
.announcement-card {
  border: 1px solid #c2cce1;
  background-color: #fff;
  padding: 0.5rem;
}
.announcement-sender {
  text-transform: capitalize;
  font-weight: 400;
}
.announcement-sender-popup {
  display: flex;
  justify-content: flex-end;
}
.audience-announcement-popup {
  flex: 1 1 0%;
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: #071437;
  text-transform: capitalize;
}
.announcement-header {
  display: flex;
  gap: 0.25rem;
  align-items: center;
}
.announcement-header-popup {
  display: flex;
  align-items: center;
  flex: 1;
}
.announcement-header-popup-start {
  display: flex;
  flex: 0.75 1;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
.announcement-header-popup-end {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}
.audience-announcement {
  text-transform: capitalize;
}
.date-picker-control {
  width: 120px;
}
.status-category-info {
  color: #1b84ff;
}
.status-category-imp {
  color: #7239ea;
}
.status-category-critical {
  color: #17c653;
}
.status-form-group .form-check-input[type='radio'] {
  border: 1px solid rgba(0, 0, 0, 0.25);
}
.status-form-group .form-check-input:checked[type='radio'] {
  background-color: #0d6efd;
}
.active_inactive_badge {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 5em;
}
.active_badge {
  background-color: #eafff1;
  color: #17c653;
  border-color: rgba(23, 198, 83, 0.2);
}
.inactive_badge {
  background-color: #f9f9f9;
  color: #78829d;
  border-color: #dbdfe9;
}
.announcement-desc-editor {
  height: 250px;
  max-height: 250px;
  overflow: auto;
}
